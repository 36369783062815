import React from "react";
import { Route, Link, useNavigate } from "react-router-dom";

const Blogs = () => {
  const navigate = useNavigate();
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width" />
      <title>Level Up Your Gaming Knowledge: Latest News, Tips | GTPLAY</title>
      {/* Favicons */}
      <link href="/_next/image/icon.png" rel="icon" />
      <link href="/_next/image/icon.png" rel="apple-touch-icon" />
      <meta
        name="description"
        content="Discover the heart and soul of GTPLAY, where we're more than just gamers; we're a community. Immerse yourself in our history, values, and aspirations."
      />
      <meta name="keywords" content="" />
      <meta name="image" content="/_next/image/banner.jpg" itemProp="image" />
      <meta
        property="og:title"
        content="About GTPLAY: Crafting Connections in the World of Gaming"
      />
      <meta property="og:image" content="/_next/image/banner.jpg" />
      <meta
        property="og:description"
        content="Discover the heart and soul of GTPLAY, where we're more than just gamers; we're a community. Immerse yourself in our history, values, and aspirations."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="/about-us" />
      <link rel="canonical" href="/about-us" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:title"
        content="About GTPLAY: Crafting Connections in the World of Gaming"
      />
      <meta
        property="twitter:description"
        content="Discover the heart and soul of GTPLAY, where we're more than just gamers; we're a community. Immerse yourself in our history, values, and aspirations."
      />
      <meta name="twitter:image" content="/_next/image/banner.jpg" />
      <meta name="next-head-count" content={18} />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        rel="stylesheet"
        data-href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
      />
      <link rel="preconnect" href="https://static.hotjar.com" />
      <link rel="preconnect" href="https://www.googletagmanager.com" />
      <link rel="preconnect" href="https://securepubads.g.doubleclick.net" />
      <link rel="preconnect" href="https://storage.googleapis.com" />
      <link rel="preconnect" href="https://cdn.hcmb.app" />
      <link
        rel="preconnect"
        href="https://storage.googleapis.com"
        crossOrigin="anonymous"
      />
      <link rel="preconnect" href="https://pagead2.googlesyndication.com" />
      <meta name="application-name" content="GTPLAY" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="GTPLAY" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content="#191b29" />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="preload"
        href="/_next/static/css/122220e8c0227507.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="/_next/static/css/122220e8c0227507.css"
        data-n-g=""
      />
      <link
        rel="preload"
        href="/_next/static/css/fc28697e051f0829.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="/_next/static/css/fc28697e051f0829.css"
        data-n-p=""
      />
      <noscript data-n-css="" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
      />
      <div id="__next">
        <div className="flex h-screen flex-col">
          <div className="bg-tertiary-base">
            <div className="mx-auto flex max-w-[var(--max-width)]  justify-between gap-4 px-6  py-2 md:px-8 lg:px-10">
              <div className="flex items-center justify-start">
                <Link to="/" title="GTPLAY">
                  <img
                    src="/_next/image/logo.png"
                    width="140px"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="  hidden items-center justify-end gap-1 md:flex">
                <Link to="/leaderboard">
                  <a
                    className="text-base font-medium rounded-lg cursor-pointer styles_ghost__wbiax flex w-full items-center justify-center gap-2"
                    href="/leaderboard"
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 27 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.793 24.7975L21.8542 21.1995L21.3007 20.1134C20.8687 20.3324 20.3674 20.371 19.9069 20.2208C19.4464 20.0706 19.0642 19.7439 18.8443 19.3123L17.9145 17.4875L14.1091 19.4266L15.0388 21.2513C15.1479 21.4653 15.2137 21.6987 15.2326 21.9382C15.2514 22.1776 15.2229 22.4184 15.1487 22.6469C15.0745 22.8753 14.956 23.0869 14.8 23.2695C14.644 23.4522 14.4536 23.6023 14.2396 23.7113L14.793 24.7975Z"
                        fill="currentColor"
                      />
                      <path
                        d="M23.0778 19.2068L12.4607 24.6166C12.1802 24.7635 11.9314 24.9642 11.7284 25.2073C11.5255 25.4503 11.3724 25.731 11.2779 26.0332C11.1835 26.3354 11.1495 26.6533 11.1779 26.9687C11.2062 27.284 11.2965 27.5907 11.4434 27.8712C11.5854 28.1576 11.8354 28.3759 12.1383 28.4781C12.4413 28.5803 12.7724 28.558 13.0589 28.4162L25.8002 21.924C26.0834 21.7756 26.296 21.5208 26.3914 21.2156C26.4868 20.9105 26.4571 20.58 26.3088 20.2967C26.1683 20.013 25.9732 19.7597 25.7347 19.5514C25.4963 19.343 25.2191 19.1837 24.9191 19.0825C24.6191 18.9813 24.302 18.9402 23.9861 18.9615C23.6702 18.9828 23.3616 19.0662 23.0778 19.2068Z"
                        fill="currentColor"
                      />
                      <path
                        d="M13.5515 26.7971C13.4061 26.5117 13.3184 26.2005 13.2933 25.8813C13.2682 25.562 13.3062 25.2409 13.4052 24.9364C13.5042 24.6318 13.6623 24.3498 13.8704 24.1063C14.0784 23.8629 14.3324 23.6628 14.6178 23.5175L12.4446 24.6248C11.8685 24.9184 11.4326 25.4288 11.2328 26.0437C11.033 26.6587 11.0857 27.3278 11.3792 27.9039C11.526 28.192 11.7812 28.4099 12.0887 28.5098C12.3962 28.6097 12.7307 28.5834 13.0188 28.4366L15.191 27.3298C14.903 27.4765 14.5684 27.5029 14.2609 27.403C13.9535 27.3031 13.6983 27.0851 13.5515 26.7971Z"
                        fill="currentColor"
                      />
                      <path
                        d="M21.462 12.7978L20.6477 13.2128C20.5045 13.2785 20.3415 13.2864 20.1928 13.2347C20.044 13.183 19.921 13.0758 19.8494 12.9354C19.7779 12.7951 19.7635 12.6325 19.8091 12.4818C19.8547 12.331 19.9569 12.2038 20.0942 12.1266L20.9086 11.7117C21.7004 11.3073 22.2994 10.6054 22.5741 9.75986C22.8488 8.91434 22.7768 7.99437 22.3739 7.20189L21.1286 4.75787C21.0551 4.61373 20.9274 4.50466 20.7735 4.45467C20.6197 4.40468 20.4522 4.41785 20.3081 4.4913L17.5923 5.87509C17.4492 5.94086 17.2862 5.94872 17.1374 5.89703C16.9886 5.84534 16.8656 5.7381 16.7941 5.59776C16.7226 5.45742 16.7082 5.29486 16.7538 5.14411C16.7994 4.99336 16.9016 4.86609 17.0389 4.78896L19.7547 3.40517C19.9687 3.29551 20.2023 3.22914 20.4421 3.20987C20.6818 3.19061 20.923 3.21882 21.1519 3.29289C21.3807 3.36696 21.5927 3.48544 21.7757 3.64155C21.9587 3.79766 22.109 3.98832 22.2182 4.20263L23.4636 6.64664C24.0127 7.72728 24.1108 8.98163 23.7362 10.1345C23.3616 11.2873 22.545 12.2445 21.4656 12.796L21.462 12.7978Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.03972 19.1275L8.22535 19.5424C7.14471 20.0916 5.89036 20.1896 4.73752 19.8151C3.58468 19.4405 2.62751 18.6239 2.07601 17.5445L0.830685 15.1005C0.610458 14.6682 0.570945 14.1663 0.720837 13.7049C0.87073 13.2436 1.19775 12.8607 1.62996 12.6404L4.34573 11.2567C4.41738 11.2164 4.49643 11.1911 4.57813 11.1821C4.65983 11.1732 4.74249 11.1809 4.82115 11.2047C4.89981 11.2285 4.97285 11.2679 5.03588 11.3207C5.09891 11.3734 5.15064 11.4383 5.18795 11.5116C5.22526 11.5848 5.24739 11.6648 5.25301 11.7468C5.25863 11.8288 5.24762 11.9111 5.22065 11.9887C5.19368 12.0663 5.1513 12.1377 5.09605 12.1986C5.0408 12.2594 4.97383 12.3085 4.89915 12.3428L2.18338 13.7266C2.03924 13.8 1.93017 13.9277 1.88018 14.0816C1.83019 14.2355 1.84336 14.4029 1.91681 14.547L3.16214 16.991C3.56646 17.7828 4.26843 18.3818 5.11395 18.6565C5.95946 18.9312 6.87944 18.8592 7.67192 18.4563L8.48629 18.0413C8.55795 18.0011 8.637 17.9757 8.7187 17.9668C8.8004 17.9579 8.88306 17.9655 8.96172 17.9894C9.04038 18.0132 9.11342 18.0526 9.17645 18.1054C9.23948 18.1581 9.29121 18.223 9.32852 18.2963C9.36583 18.3695 9.38796 18.4495 9.39358 18.5315C9.3992 18.6135 9.38819 18.6958 9.36122 18.7734C9.33425 18.851 9.29186 18.9224 9.23662 18.9833C9.18137 19.0441 9.1144 19.0932 9.03972 19.1275Z"
                        fill="currentColor"
                      />
                      <path
                        d="M14.3772 0.809642L2.69439 6.76249C2.26957 6.98517 1.95057 7.36744 1.80754 7.82526C1.66451 8.28308 1.70915 8.77897 1.93164 9.20388L5.46286 16.1341C6.45046 18.1632 8.20216 19.7181 10.3341 20.4581C12.4661 21.198 14.8044 21.0626 16.8368 20.0816C18.825 19.014 20.3089 17.2018 20.9634 15.042C21.6178 12.8823 21.3895 10.5512 20.3283 8.55954L16.7971 1.62934C16.5844 1.20019 16.2102 0.8729 15.7566 0.719237C15.3029 0.565575 14.8069 0.598086 14.3772 0.809642Z"
                        fill="currentColor"
                      />
                      <path
                        d="M18.3497 17.2579C16.3064 18.2742 13.9434 18.4385 11.7791 17.7148C9.61474 16.9911 7.82583 15.4385 6.80474 13.3976L3.27352 6.46741L2.73002 6.74435C2.29808 6.96474 1.97134 7.34763 1.82163 7.80884C1.67192 8.27006 1.71148 8.77185 1.93163 9.2039L5.46285 16.1341C6.48393 18.175 8.27284 19.7276 10.4372 20.4513C12.6015 21.175 14.9645 21.0107 17.0078 19.9944C18.0444 19.4638 18.9599 18.7242 19.6966 17.8223C20.4332 16.9203 20.9752 15.8756 21.2882 14.7539C20.5186 15.8072 19.513 16.6655 18.352 17.2602L18.3497 17.2579Z"
                        fill="currentColor"
                      />
                      <path
                        d="M14.9304 8.3403C14.8703 8.28033 14.7966 8.23585 14.7156 8.21068C14.6345 8.18551 14.5485 8.18041 14.4651 8.19581L12.6256 8.5394L10.9842 7.64625C10.909 7.61032 10.8265 7.59218 10.7432 7.59325C10.6598 7.59431 10.5778 7.61455 10.5036 7.65239C10.4293 7.69023 10.3648 7.74466 10.3149 7.81146C10.2651 7.87825 10.2313 7.95563 10.2161 8.03759L9.97397 9.89274L8.61479 11.179C8.55327 11.2374 8.50688 11.31 8.47965 11.3903C8.45241 11.4707 8.44513 11.5565 8.45845 11.6403C8.47176 11.7241 8.50527 11.8034 8.55608 11.8714C8.60688 11.9393 8.67346 11.9939 8.75007 12.0304L10.4391 12.8319L11.2426 14.5213C11.2789 14.5984 11.3336 14.6655 11.4018 14.7167C11.4701 14.7678 11.5498 14.8015 11.634 14.8147C11.7183 14.8279 11.8045 14.8203 11.8851 14.7925C11.9657 14.7647 12.0383 14.7175 12.0965 14.6552L13.3827 13.2984L15.2368 13.0562C15.2959 13.0483 15.3532 13.0305 15.4064 13.0035C15.4685 12.9718 15.5237 12.9281 15.5687 12.8747C15.6136 12.8214 15.6474 12.7596 15.6681 12.693C15.6888 12.6264 15.6959 12.5563 15.689 12.4869C15.6822 12.4175 15.6614 12.3502 15.6281 12.2889L14.7333 10.645L15.0766 8.80565C15.0918 8.72204 15.0864 8.63599 15.0609 8.55492C15.0355 8.47386 14.9907 8.4002 14.9304 8.3403Z"
                        fill="currentColor"
                      />
                    </svg>
                    Leaderboard
                  </a>
                </Link>
                <Link to="/favorites">
                  <a
                    className="text-base font-medium rounded-lg cursor-pointer styles_ghost__wbiax flex w-full items-center justify-center gap-2"
                    href="/favorites"
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 22 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.171 2.137C16.983 -2.001 10.71 0.436996 10.71 4.643C10.71 0.442997 4.436 -2.002 1.248 2.137C-2.046 6.415 1.2 13.91 10.71 18.216C20.219 13.91 23.466 6.415 20.171 2.137Z"
                        fill="currentColor"
                      />
                    </svg>
                    Favorites
                  </a>
                </Link>
                <button className="text-base font-medium rounded-lg cursor-pointer styles_ghost__wbiax flex min-w-fit items-center justify-center gap-2">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 48 48"
                    enableBackground="new 0 0 48 48"
                    height={20}
                    width={20}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
	c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
	c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
	C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>{" "}
                  <span>Login</span>
                </button>
              </div>
            </div>
          </div>
          <main className="relative w-full max-w-[var(--max-width)] flex-1 self-center pb-4">
            <div className="pt-0 pb-4 px-3 md:px-8 lg:px-10">
              <div className="mt-6 flex flex-col items-start justify-center">
                <h1
                  className="styles_primary__s90U2 styles_title__20JPj"
                  style={{ textAlign: "center" }}
                >
                  Comming Soon...
                </h1>
              </div>
            </div>
          </main>
          <footer className=" bg-tertiary-base pb-8 md:pb-0">
            <div className="styles_footer__ZrjEu">
              <div className="pt-0 pb-4 px-3 md:px-8 lg:px-10">
                <div className="styles_container__6K37q">
                  <div className="styles_website__fPkEg">
                    <Link to="/" className="styles_brand__vI210" title="GTPLAY">
                      <div className="styles_logo__Ra10p">
                        <img
                          alt="gtplaylogo"
                          loading="lazy"
                          width={163}
                          height={28}
                          decoding="async"
                          data-nimg={1}
                          style={{ color: "transparent" }}
                          srcSet="/_next/image/logo.png"
                          src="/_next/image/logo.png"
                        />
                      </div>
                    </Link>
                    <p className="styles_copyright__gnwRO">
                      Play anywhere, anytime.
                    </p>
                  </div>
                  <div className="styles_links__xdfqT">
                    <div className="styles_linkGroup__R7_Hu">
                      <span className="styles_title__soOdA">Legal</span>
                      <Link to="/privacy-policy">
                        <a
                          href="/privacy-policy"
                          className="styles_link__VH197"
                          target="_blank"
                          rel="noopener"
                        >
                          Privacy Policy
                        </a>
                      </Link>
                      <Link to="/terms-and-conditions">
                        <a
                          href="/terms-and-conditions"
                          className="styles_link__VH197"
                          target="_blank"
                          rel="noopener"
                        >
                          Terms and Conditions
                        </a>
                      </Link>
                    </div>
                    <div className="styles_linkGroup__R7_Hu">
                      <span className="styles_title__soOdA">For Gamers</span>
                      <Link to="/about-us" className="styles_link__VH197">
                        About Us
                      </Link>
                      <Link to="/blogs" className="styles_link__VH197">
                        Blogs
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <div className="z-2 fixed inset-0 top-auto flex min-h-[62px] items-center justify-center bg-black   md:hidden">
            <div className="grid grid-cols-5">
              <a
                className="font-medium cursor-pointer styles_ghost__wbiax flex w-full flex-col items-center justify-center gap-1 rounded-none text-xs"
                href="/"
              >
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 27 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  size={20}
                >
                  <path
                    d="M25.923 15.36H21.604C21.4778 15.3636 21.3523 15.3397 21.2363 15.2899C21.1203 15.2402 21.0164 15.1658 20.932 15.072L13.443 7.2L5.95599 15.072C5.8715 15.1658 5.76765 15.2401 5.65163 15.2898C5.53561 15.3396 5.41017 15.3635 5.28399 15.36H0.96399C0.776253 15.3747 0.589011 15.326 0.432325 15.2215C0.275638 15.117 0.158597 14.963 0.0999895 14.784C0.0103486 14.6116 -0.0194361 14.4143 0.0153213 14.2232C0.0500787 14.032 0.147406 13.8578 0.29199 13.728L12.772 0.288C12.9661 0.103121 13.2239 0 13.492 0C13.7601 0 14.0179 0.103121 14.212 0.288L26.692 13.728C26.8283 13.8686 26.9186 14.0474 26.9507 14.2406C26.9829 14.4338 26.9555 14.6322 26.8721 14.8095C26.7887 14.9867 26.6533 15.1343 26.4839 15.2326C26.3146 15.331 26.1182 15.3754 25.923 15.36Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M21.604 17.284C21.2124 17.2859 20.8244 17.2106 20.462 17.0623C20.0996 16.9141 19.7699 16.6958 19.492 16.42L13.444 9.98401L7.39599 16.416C7.11837 16.6925 6.78891 16.9115 6.42649 17.0605C6.06407 17.2094 5.67582 17.2854 5.28399 17.284H3.84399V24.004C3.83626 24.1321 3.8558 24.2604 3.90131 24.3803C3.94682 24.5003 4.01727 24.6093 4.108 24.7C4.19873 24.7907 4.30769 24.8612 4.42767 24.9067C4.54764 24.9522 4.67591 24.9717 4.80399 24.964H22.084C22.2121 24.9717 22.3403 24.9522 22.4603 24.9067C22.5803 24.8612 22.6893 24.7907 22.78 24.7C22.8707 24.6093 22.9412 24.5003 22.9867 24.3803C23.0322 24.2604 23.0517 24.1321 23.044 24.004V17.284H21.604Z"
                    fill="currentColor"
                  ></path>
                </svg>
                Home
              </a>
              <a
                className="font-medium cursor-pointer styles_ghost__wbiax flex w-full flex-col items-center justify-center gap-1 rounded-none text-xs"
                href="/leaderboard"
              >
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 27 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  size={20}
                >
                  <path
                    d="M14.793 24.7975L21.8542 21.1995L21.3007 20.1134C20.8687 20.3324 20.3674 20.371 19.9069 20.2208C19.4464 20.0706 19.0642 19.7439 18.8443 19.3123L17.9145 17.4875L14.1091 19.4266L15.0388 21.2513C15.1479 21.4653 15.2137 21.6987 15.2326 21.9382C15.2514 22.1776 15.2229 22.4184 15.1487 22.6469C15.0745 22.8753 14.956 23.0869 14.8 23.2695C14.644 23.4522 14.4536 23.6023 14.2396 23.7113L14.793 24.7975Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M23.0778 19.2068L12.4607 24.6166C12.1802 24.7635 11.9314 24.9642 11.7284 25.2073C11.5255 25.4503 11.3724 25.731 11.2779 26.0332C11.1835 26.3354 11.1495 26.6533 11.1779 26.9687C11.2062 27.284 11.2965 27.5907 11.4434 27.8712C11.5854 28.1576 11.8354 28.3759 12.1383 28.4781C12.4413 28.5803 12.7724 28.558 13.0589 28.4162L25.8002 21.924C26.0834 21.7756 26.296 21.5208 26.3914 21.2156C26.4868 20.9105 26.4571 20.58 26.3088 20.2967C26.1683 20.013 25.9732 19.7597 25.7347 19.5514C25.4963 19.343 25.2191 19.1837 24.9191 19.0825C24.6191 18.9813 24.302 18.9402 23.9861 18.9615C23.6702 18.9828 23.3616 19.0662 23.0778 19.2068Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M13.5515 26.7971C13.4061 26.5117 13.3184 26.2005 13.2933 25.8813C13.2682 25.562 13.3062 25.2409 13.4052 24.9364C13.5042 24.6318 13.6623 24.3498 13.8704 24.1063C14.0784 23.8629 14.3324 23.6628 14.6178 23.5175L12.4446 24.6248C11.8685 24.9184 11.4326 25.4288 11.2328 26.0437C11.033 26.6587 11.0857 27.3278 11.3792 27.9039C11.526 28.192 11.7812 28.4099 12.0887 28.5098C12.3962 28.6097 12.7307 28.5834 13.0188 28.4366L15.191 27.3298C14.903 27.4765 14.5684 27.5029 14.2609 27.403C13.9535 27.3031 13.6983 27.0851 13.5515 26.7971Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M21.462 12.7978L20.6477 13.2128C20.5045 13.2785 20.3415 13.2864 20.1928 13.2347C20.044 13.183 19.921 13.0758 19.8494 12.9354C19.7779 12.7951 19.7635 12.6325 19.8091 12.4818C19.8547 12.331 19.9569 12.2038 20.0942 12.1266L20.9086 11.7117C21.7004 11.3073 22.2994 10.6054 22.5741 9.75986C22.8488 8.91434 22.7768 7.99437 22.3739 7.20189L21.1286 4.75787C21.0551 4.61373 20.9274 4.50466 20.7735 4.45467C20.6197 4.40468 20.4522 4.41785 20.3081 4.4913L17.5923 5.87509C17.4492 5.94086 17.2862 5.94872 17.1374 5.89703C16.9886 5.84534 16.8656 5.7381 16.7941 5.59776C16.7226 5.45742 16.7082 5.29486 16.7538 5.14411C16.7994 4.99336 16.9016 4.86609 17.0389 4.78896L19.7547 3.40517C19.9687 3.29551 20.2023 3.22914 20.4421 3.20987C20.6818 3.19061 20.923 3.21882 21.1519 3.29289C21.3807 3.36696 21.5927 3.48544 21.7757 3.64155C21.9587 3.79766 22.109 3.98832 22.2182 4.20263L23.4636 6.64664C24.0127 7.72728 24.1108 8.98163 23.7362 10.1345C23.3616 11.2873 22.545 12.2445 21.4656 12.796L21.462 12.7978Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M9.03972 19.1275L8.22535 19.5424C7.14471 20.0916 5.89036 20.1896 4.73752 19.8151C3.58468 19.4405 2.62751 18.6239 2.07601 17.5445L0.830685 15.1005C0.610458 14.6682 0.570945 14.1663 0.720837 13.7049C0.87073 13.2436 1.19775 12.8607 1.62996 12.6404L4.34573 11.2567C4.41738 11.2164 4.49643 11.1911 4.57813 11.1821C4.65983 11.1732 4.74249 11.1809 4.82115 11.2047C4.89981 11.2285 4.97285 11.2679 5.03588 11.3207C5.09891 11.3734 5.15064 11.4383 5.18795 11.5116C5.22526 11.5848 5.24739 11.6648 5.25301 11.7468C5.25863 11.8288 5.24762 11.9111 5.22065 11.9887C5.19368 12.0663 5.1513 12.1377 5.09605 12.1986C5.0408 12.2594 4.97383 12.3085 4.89915 12.3428L2.18338 13.7266C2.03924 13.8 1.93017 13.9277 1.88018 14.0816C1.83019 14.2355 1.84336 14.4029 1.91681 14.547L3.16214 16.991C3.56646 17.7828 4.26843 18.3818 5.11395 18.6565C5.95946 18.9312 6.87944 18.8592 7.67192 18.4563L8.48629 18.0413C8.55795 18.0011 8.637 17.9757 8.7187 17.9668C8.8004 17.9579 8.88306 17.9655 8.96172 17.9894C9.04038 18.0132 9.11342 18.0526 9.17645 18.1054C9.23948 18.1581 9.29121 18.223 9.32852 18.2963C9.36583 18.3695 9.38796 18.4495 9.39358 18.5315C9.3992 18.6135 9.38819 18.6958 9.36122 18.7734C9.33425 18.851 9.29186 18.9224 9.23662 18.9833C9.18137 19.0441 9.1144 19.0932 9.03972 19.1275Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M14.3772 0.809642L2.69439 6.76249C2.26957 6.98517 1.95057 7.36744 1.80754 7.82526C1.66451 8.28308 1.70915 8.77897 1.93164 9.20388L5.46286 16.1341C6.45046 18.1632 8.20216 19.7181 10.3341 20.4581C12.4661 21.198 14.8044 21.0626 16.8368 20.0816C18.825 19.014 20.3089 17.2018 20.9634 15.042C21.6178 12.8823 21.3895 10.5512 20.3283 8.55954L16.7971 1.62934C16.5844 1.20019 16.2102 0.8729 15.7566 0.719237C15.3029 0.565575 14.8069 0.598086 14.3772 0.809642Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M18.3497 17.2579C16.3064 18.2742 13.9434 18.4385 11.7791 17.7148C9.61474 16.9911 7.82583 15.4385 6.80474 13.3976L3.27352 6.46741L2.73002 6.74435C2.29808 6.96474 1.97134 7.34763 1.82163 7.80884C1.67192 8.27006 1.71148 8.77185 1.93163 9.2039L5.46285 16.1341C6.48393 18.175 8.27284 19.7276 10.4372 20.4513C12.6015 21.175 14.9645 21.0107 17.0078 19.9944C18.0444 19.4638 18.9599 18.7242 19.6966 17.8223C20.4332 16.9203 20.9752 15.8756 21.2882 14.7539C20.5186 15.8072 19.513 16.6655 18.352 17.2602L18.3497 17.2579Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M14.9304 8.3403C14.8703 8.28033 14.7966 8.23585 14.7156 8.21068C14.6345 8.18551 14.5485 8.18041 14.4651 8.19581L12.6256 8.5394L10.9842 7.64625C10.909 7.61032 10.8265 7.59218 10.7432 7.59325C10.6598 7.59431 10.5778 7.61455 10.5036 7.65239C10.4293 7.69023 10.3648 7.74466 10.3149 7.81146C10.2651 7.87825 10.2313 7.95563 10.2161 8.03759L9.97397 9.89274L8.61479 11.179C8.55327 11.2374 8.50688 11.31 8.47965 11.3903C8.45241 11.4707 8.44513 11.5565 8.45845 11.6403C8.47176 11.7241 8.50527 11.8034 8.55608 11.8714C8.60688 11.9393 8.67346 11.9939 8.75007 12.0304L10.4391 12.8319L11.2426 14.5213C11.2789 14.5984 11.3336 14.6655 11.4018 14.7167C11.4701 14.7678 11.5498 14.8015 11.634 14.8147C11.7183 14.8279 11.8045 14.8203 11.8851 14.7925C11.9657 14.7647 12.0383 14.7175 12.0965 14.6552L13.3827 13.2984L15.2368 13.0562C15.2959 13.0483 15.3532 13.0305 15.4064 13.0035C15.4685 12.9718 15.5237 12.9281 15.5687 12.8747C15.6136 12.8214 15.6474 12.7596 15.6681 12.693C15.6888 12.6264 15.6959 12.5563 15.689 12.4869C15.6822 12.4175 15.6614 12.3502 15.6281 12.2889L14.7333 10.645L15.0766 8.80565C15.0918 8.72204 15.0864 8.63599 15.0609 8.55492C15.0355 8.47386 14.9907 8.4002 14.9304 8.3403Z"
                    fill="currentColor"
                  ></path>
                </svg>
                Leaderboard
              </a>
              <div className="mx-auto flex  -translate-y-3 items-center justify-center  ">
                <Link to="/" title="GTPLAY">
                  <span className="mx-auto flex h-12 w-12  items-center justify-center rounded-full bg-[#87cdff] p-2 shadow-[0px_0px_0px_6px_rgba(0,142,241,0.42)]">
                    <svg
                      width="30px"
                      height="30px"
                      viewBox="0 0 56 58"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      alt="logo"
                    >
                      <g clipPath="url(#clip0_5_2)">
                        <path
                          d="M29.5651 15.8898C29.3387 15.8543 29.1232 15.7686 28.9344 15.6389C28.7455 15.5092 28.5882 15.3388 28.4738 15.1403C28.3595 14.9417 28.2911 14.7201 28.2736 14.4917C28.2562 14.2633 28.2902 14.0338 28.3731 13.8202C28.746 12.8696 32.1462 4.46283 37.2739 2.42937C37.6516 2.27969 38.0733 2.28619 38.4462 2.44743C38.8191 2.60867 39.1127 2.91145 39.2624 3.28916C39.4121 3.66687 39.4056 4.08857 39.2443 4.46149C39.0831 4.83441 38.7803 5.128 38.4026 5.27769C35.092 6.5896 32.1023 12.6919 31.2306 14.9286C31.1044 15.2549 30.8704 15.5282 30.5674 15.703C30.2644 15.8779 29.9107 15.9438 29.5651 15.8898V15.8898Z"
                          fill="#098BEA"
                        ></path>
                        <path
                          d="M27.9262 9.40928C42.9142 9.66407 54.8392 23.1066 54.5617 39.4336C54.2841 55.7606 43 56.0459 28.015 55.7912C13.03 55.5364 0.00810854 54.8379 0.285668 38.5109C0.563227 22.1839 12.9382 9.15448 27.9262 9.40928Z"
                          fill="#F3F3F4"
                        ></path>
                        <path
                          d="M27.7303 20.931C32.2795 21.0084 36.6117 22.8897 39.7739 26.1612C42.936 29.4327 44.669 33.8264 44.5917 38.3756C44.4306 47.8486 37.3009 48.0045 27.8259 47.8434C18.3509 47.6824 10.1246 47.2664 10.2857 37.7924C10.363 33.2432 12.2443 28.911 15.5158 25.7488C18.7873 22.5867 23.181 20.8537 27.7303 20.931V20.931Z"
                          fill="#181B29"
                        ></path>
                        <path
                          d="M38.164 6.63167C39.8201 6.65983 41.1854 5.34015 41.2136 3.68408C41.2417 2.02802 39.9221 0.662693 38.266 0.63454C36.6099 0.606387 35.2446 1.92607 35.2164 3.58213C35.1883 5.23819 36.508 6.60352 38.164 6.63167Z"
                          fill="#098BEA"
                        ></path>
                        <path
                          d="M21.2451 41.9127C23.178 41.9455 24.795 39.0338 24.8566 35.4092C24.9182 31.7845 23.4012 28.8195 21.4682 28.7867C19.5352 28.7538 17.9182 31.6655 17.8566 35.2902C17.795 38.9148 19.3121 41.8798 21.2451 41.9127Z"
                          fill="#5BB9FE"
                        ></path>
                        <path
                          d="M33.2788 42.1172C35.3327 42.1522 37.0477 39.2421 37.1093 35.6175C37.1709 31.9928 35.5558 29.0262 33.5019 28.9912C31.4479 28.9563 29.7329 31.8664 29.6713 35.491C29.6097 39.1157 31.2248 42.0823 33.2788 42.1172Z"
                          fill="#5CBAFF"
                        ></path>
                        <path
                          d="M21.2451 41.9127C23.178 41.9455 24.795 39.0338 24.8566 35.4092C24.9182 31.7845 23.4012 28.8195 21.4682 28.7867C19.5352 28.7538 17.9182 31.6655 17.8566 35.2902C17.795 38.9148 19.3121 41.8798 21.2451 41.9127Z"
                          fill="#098BEA"
                        ></path>
                        <path
                          d="M33.2797 42.1163C35.3337 42.1512 37.0487 39.2412 37.1103 35.6165C37.1719 31.9919 35.5568 29.0252 33.5029 28.9903C31.4489 28.9554 29.7339 31.8654 29.6723 35.4901C29.6107 39.1147 31.2258 42.0814 33.2797 42.1163Z"
                          fill="#098BEA"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_5_2">
                          <rect
                            width="55.077"
                            height="57.116"
                            fill="white"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </Link>
              </div>
              <Link to="/favorites">
                <a
                  className="font-medium cursor-pointer styles_ghost__wbiax flex w-full flex-col items-center justify-center gap-1 rounded-none text-xs"
                  href="/favorites"
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    size={20}
                  >
                    <path
                      d="M20.171 2.137C16.983 -2.001 10.71 0.436996 10.71 4.643C10.71 0.442997 4.436 -2.002 1.248 2.137C-2.046 6.415 1.2 13.91 10.71 18.216C20.219 13.91 23.466 6.415 20.171 2.137Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Favorites
                </a>
              </Link>
              <button className="text-base font-medium rounded-lg cursor-pointer styles_ghost__wbiax flex min-w-fit items-center justify-center gap-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  version="1.1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 48 48"
                  enableBackground="new 0 0 48 48"
                  height={20}
                  width={20}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#FFC107"
                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
	c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
	c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                  />
                  <path
                    fill="#FF3D00"
                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
	C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                  />
                  <path
                    fill="#4CAF50"
                    d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                  />
                  <path
                    fill="#1976D2"
                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                  ></path>
                </svg>{" "}
                <span>Login</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
