import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./components/Home";
import Leaderboard from "./components/Leaderboard";
import Favorites from "./components/Favorites";
import About from "./components/About";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndCondition from "./components/TermsAndConditions";
import Blogs from "./components/Blogs";
import ArcadeGames from "./components/ArcadGames";
import BoardGames from "./components/BoardGames";
import CardGames from "./components/CardGames";
import FunGames from "./components/FunGames";
import PuzzleGames from "./components/PuzzleGames";
import RacingGames from "./components/RacingGames";
import ShooterGames from "./components/ShooterGames";
import SportsGames from "./components/SportsGames";
import EightBallPool from "./components/games/8-ball-pool";
import EightBallPoolGame from "./components/games/8-ball-pool/game";
import TwoThousandFourtyEight from "./components/games/2048";
import TwoThousandFourtyEightGame from "./components/games/2048/game";
import BasketHop from "./components/games/basket-hop";
import BasketHopGame from "./components/games/basket-hop/game";
import CallBreak from "./components/games/callbreak";
import CallBreakGame from "./components/games/callbreak/game";
import ChopEmAll from "./components/games/chop-em-all";
import ChopEmAllGame from "./components/games/chop-em-all/game";
import CricketStar from "./components/games/cricket-stars";
import CricketStarGame from "./components/games/cricket-stars/game";
import EmojiMinesweeper from "./components/games/emoji-minesweeper";
import EmojiMinesweeperGame from "./components/games/emoji-minesweeper/game";
import Chess from "./components/games/epic-chess";
import ChessGame from "./components/games/epic-chess/game";
import Quiz from "./components/games/epic-quiz";
import QuizGame from "./components/games/epic-quiz/game";
import FlyingBird from "./components/games/flying-bird";
import FlyingBirdGame from "./components/games/flying-bird/game";
import FusionBlocks from "./components/games/fusion-blocks";
import FusionBlocksGame from "./components/games/fusion-blocks/game";
import Hetrixs from "./components/games/hextris";
import HetrixsGame from "./components/games/hextris/game";
import HighTower from "./components/games/high-tower";
import HighTowerGame from "./components/games/high-tower/game";
import HillClimbRacing from "./components/games/hill-climb-racing";
import HillClimbRacingGame from "./components/games/hill-climb-racing/game";
import IPLCricket from "./components/games/ipl-cricket-game";
import IPLCricketGame from "./components/games/ipl-cricket-game/game";
import KnifeDash from "./components/games/knife-dash";
import KnifeDashGame from "./components/games/knife-dash/game";
import LudoMatka from "./components/games/ludo-matka";
import LudoMatkaGame from "./components/games/ludo-matka/game";
import MeteorAttack from "./components/games/meteor-attack";
import MeteorAttackGame from "./components/games/meteor-attack/game";
import PacMan from "./components/games/pac-man";
import PacManGame from "./components/games/pac-man/game";
import RunoutChamp from "./components/games/runout-champ";
import RunoutChampGame from "./components/games/runout-champ/game";
import SankeAndLadder from "./components/games/snake-and-ladder";
import SankeAndLadderGame from "./components/games/snake-and-ladder/game";
import SpaceInvader from "./components/games/space-invader";
import SpaceInvaderGame from "./components/games/space-invader/game";
import StrikeBalls from "./components/games/strike-balls";
import StrikeBallsGame from "./components/games/strike-balls/game";
import Sudo from "./components/games/sudo";
import SudoGame from "./components/games/sudo/game";
import TrafficRider from "./components/games/traffic-rider";
import TrafficRiderGame from "./components/games/traffic-rider/game";
import Zombvival from "./components/games/zombvival";
import ZombvivalGame from "./components/games/zombvival/game";
import CarRacing from "./components/games/car-racing-game";
import CarRacingGame from "./components/games/car-racing-game/game";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/about-us" element={<About />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="/category/puzzle-games" element={<PuzzleGames />} />
        <Route path="/category/fun-games" element={<FunGames />} />
        <Route path="/category/board-games" element={<BoardGames />} />
        <Route path="/category/sports-games" element={<SportsGames />} />
        <Route path="/category/arcade-games" element={<ArcadeGames />} />
        <Route path="/category/shooter-games" element={<ShooterGames />} />
        <Route path="/category/card-games" element={<CardGames />} />
        <Route path="/category/racing-games" element={<RacingGames />} />
        <Route path="/games/8-ball-pool" element={<EightBallPool />} />
        <Route path="/games/8-ball-pool/game" element={<EightBallPoolGame />} />
        <Route path="/games/2048" element={<TwoThousandFourtyEight />} />
        <Route
          path="/games/2048/game"
          element={<TwoThousandFourtyEightGame />}
        />
        <Route path="/games/basket-hop" element={<BasketHop />} />
        <Route path="/games/basket-hop/game" element={<BasketHopGame />} />
        <Route path="/games/callbreak" element={<CallBreak />} />
        <Route path="/games/callbreak/game" element={<CallBreak />} />
        <Route path="/games/car-racing-game" element={<CarRacing />} />
        <Route path="/games/car-racing-game/game" element={<CarRacingGame />} />
        <Route path="/games/chop-em-all" element={<ChopEmAll />} />
        <Route path="/games/chop-em-all/game" element={<ChopEmAllGame />} />
        <Route path="/games/cricket-stars" element={<CricketStar />} />
        <Route path="/games/cricket-stars/game" element={<CricketStarGame />} />
        <Route path="/games/emoji-minesweeper" element={<EmojiMinesweeper />} />
        <Route
          path="/games/emoji-minesweeper/game"
          element={<EmojiMinesweeperGame />}
        />
        <Route path="/games/epic-chess" element={<Chess />} />
        <Route path="/games/epic-chess/game" element={<ChessGame />} />
        <Route path="/games/epic-quiz" element={<Quiz />} />
        <Route path="/games/epic-quiz/game" element={<QuizGame />} />
        <Route path="/games/flying-bird" element={<FlyingBird />} />
        <Route path="/games/flying-bird/game" element={<FlyingBirdGame />} />
        <Route path="/games/fusion-blocks" element={<FusionBlocks />} />
        <Route
          path="/games/fusion-blocks/game"
          element={<FusionBlocksGame />}
        />
        <Route path="/games/hextris" element={<Hetrixs />} />
        <Route path="/games/hextris/game" element={<HetrixsGame />} />
        <Route path="/games/high-tower" element={<HighTower />} />
        <Route path="/games/high-tower/game" element={<HighTowerGame />} />
        <Route path="/games/hill-climb-racing" element={<HillClimbRacing />} />
        <Route
          path="/games/hill-climb-racing/game"
          element={<HillClimbRacingGame />}
        />
        <Route path="/games/ipl-cricket-game" element={<IPLCricket />} />
        <Route
          path="/games/ipl-cricket-game/game"
          element={<IPLCricketGame />}
        />
        <Route path="/games/knife-dash" element={<KnifeDash />} />
        <Route path="/games/knife-dash/game" element={<KnifeDashGame />} />
        <Route path="/games/ludo-matka" element={<LudoMatka />} />
        <Route path="/games/ludo-matka/game" element={<LudoMatkaGame />} />
        <Route path="/games/meteor-attack" element={<MeteorAttack />} />
        <Route
          path="/games/meteor-attack/game"
          element={<MeteorAttackGame />}
        />
        <Route path="/games/pac-man" element={<PacMan />} />
        <Route path="/games/pac-man/game" element={<PacManGame />} />
        <Route path="/games/runout-champ" element={<RunoutChamp />} />
        <Route path="/games/runout-champ/game" element={<RunoutChampGame />} />
        <Route path="/games/snake-and-ladder" element={<SankeAndLadder />} />
        <Route
          path="/games/snake-and-ladder/game"
          element={<SankeAndLadderGame />}
        />
        <Route path="/games/space-invader" element={<SpaceInvader />} />
        <Route
          path="/games/space-invader/game"
          element={<SpaceInvaderGame />}
        />
        <Route path="/games/strike-balls" element={<StrikeBalls />} />
        <Route path="/games/strike-balls/game" element={<StrikeBallsGame />} />
        <Route path="/games/sudo" element={<Sudo />} />
        <Route path="/games/sudo/game" element={<SudoGame />} />
        <Route path="/games/traffic-rider" element={<TrafficRider />} />
        <Route
          path="/games/traffic-rider/game"
          element={<TrafficRiderGame />}
        />
        <Route path="/games/zombvival" element={<Zombvival />} />
        <Route path="/games/zombvival/game" element={<ZombvivalGame />} />
      </Routes>
    </Router>
  );
}

export default App;
